import React from "react";
import { FaTwitch, FaTwitter, FaInstagram, FaYoutube, FaFacebook, FaLinkedin, FaGithub, FaDiscord } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

const bioLinkData = {
  profileImage: "/images/profile/240x240.jpg",
  name: "Altay Kececi",
  description:
    "Selam! Modern ve etkileyici web deneyimleri tasarlamak için buradayım! Kullanıcı dostu arayüzler, yenilikçi çözümler ve pixel-perfect tasarımlar için bana katılın. İster en yeni teknolojilerle çalışalım, ister zamansız tasarım prensiplerini hayata geçirelim, her zaman heyecan verici projelerle buradayım!",
  links: [
    /*{
      url: "https://twitch.tv/example",
      icon: <FaTwitch size={20} />,
      color: "bg-[#9146FF]",
      title: "twitch.tv/example",
      description: "Join me for epic gameplay",
    },   */  
    {
      url: "https://linkedin.com/in/altaykececi",
      icon: <FaLinkedin size={20} />,
      color: "bg-[#0077B5]",
      title: "linkedin.com/in/altaykececi",
      description: "Benimle profesyonel olarak bağlantı kurun",
    },
    {
      url: "https://github.com/altaykececi",
      icon: <FaGithub size={20} />,
      color: "bg-[#333]",
      title: "github.com/altaykececi",
      description: "Projelerime göz atın",
    },
    {
      url: "https://instagram.com/altaykcc",
      icon: <FaInstagram size={20} />,
      color: "bg-[#E1306C]",
      title: "instagram.com/altaykcc",
      description: "Son fotoğraflarımı görüntüleyin",
    },
    {
      url: "https://x.com/altaykcc",
      icon: <FaX size={20} />,
      color: "bg-[#000]",
      title: "x.com/altaykcc",
      description: "Beni takip edebilirsiniz.",
    },
    {
      url: "https://www.youtube.com/@Middle-earthCoders",
      icon: <FaYoutube size={20} />,
      color: "bg-[#FF0000]",
      title: "youtube.com/Middle-earthCoders",
      description: "Son videolarımı izleyin.",
    },
   /* {
      url: "https://facebook.com/example",
      icon: <FaFacebook size={20} />,
      color: "bg-[#4267B2]",
      title: "facebook.com/example",
      description: "Like my page for updates",
    },
    {
      url: "https://discord.gg/example",
      icon: <FaDiscord size={20} />,
      color: "bg-[#5865F2]",
      title: "discord.gg/example",
      description: "Join my Discord community",
    },*/
  ],
};

export default bioLinkData;
