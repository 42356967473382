import React from "react";
import { RiPhoneLine, RiMailLine, RiMapPinLine } from "react-icons/ri";

const contact = [
  {
    icon: <RiPhoneLine />, 
    title: "Telefon",
    desc: "+90 505 597 0735",
  },
  {
    icon: <RiMailLine />,
    title: "E-posta",
    desc: "altaykececi.dev@gmail.com",
  },
  {
    icon: <RiMapPinLine />,
    title: "Adres",
    desc: "Gebze/Kocaeli",
  },
];

export default contact;
