import React from "react";
import { FaReact } from "react-icons/fa";
import { HiLink } from "react-icons/hi";
import { 
  IoLogoGithub, 
  IoLogoPython,
  IoLogoNodejs,
} from "react-icons/io5";

const projectsData = {
  header: {
    title: "Projeler",
    desc: "Farklı dillerde geliştirilen projeler",
  },
  activeButton: "react",
  type: "projects",
  buttons: [
    {
      name: "React",
      tag: "react",
      desc: "React ile geliştirilen projeler",
      icon: <FaReact size={"20px"} />,
    },
    {
      name: "Css",
      tag: "css",
      desc: "Css ile geliştirilen projeler",
      icon: <IoLogoNodejs size={"20px"} />,
    }, 
  ],
  items: [
    {
      id: 1,
      imageTabMenu: "/images/projects/projects/monolog.webp",
      imageDetailsPage: "/images/projects/projects/monolog.webp",
      title: "Monolog Cv Theme",
      slug: "monolog-cv-theme",
      descTabMenu: 
        "React ve tailwindcss kullanarak geliştirmiş olduğum çok kapsamlı, full responsive bir react projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Modern ve profesyonel bir portfolio teması olan Monolog CV, React ve Redux Toolkit kullanılarak geliştirilmiş kapsamlı bir web uygulamasıdır. Kullanıcı deneyimini en üst düzeyde tutmak için tasarlanmış bu tema, hem kişisel portfolyo hem de kurumsal kullanım için ideal bir çözüm sunmaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Redux Toolkit ile gelişmiş durum yönetimi</li>
              <li>Koyu/Açık tema desteği</li>
              <li>Çoklu dil desteği</li>
              <li>Özelleştirilebilir renk şemaları</li>
              <li>Entegre alışveriş sepeti sistemi</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern React hooks ve custom hooks</li>
              <li>Responsive tasarım</li>
              <li>SEO optimizasyonu</li>
              <li>Yüksek performans</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss", "redux toolkit"],  // Project tags
      date: "2024",  // Year of the project
      url: "https://kccthemes.buzz/",  // Link to the live project
      githubUrl: "#",  // GitHub repository link (can be added later)
      tagsHead: "Technologies",  // Title for the technologies section
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },  // Add specific technology tags or icons as needed
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "/images/projects/projects/monolog.webp",  // Additional images for the project (if any) 
      ],
      featuresList: [
        "Modern React hooks ve custom hooks",  // Project feature 1
        "Responsive tasarım",  // Project feature 2
        "SEO optimizasyonu",  // Project feature 3
        "Modern UI/UX",  // Project feature 4
        "Yüksek performans",  // Project feature 5
      ],
      linkHead: "Links",  // Section title for links
      links: [
        {
          icon: <HiLink />,  // Icon for the link (adjust according to your usage)
          linkTitle: "Live Demo",  // Title for the link
          link: "https://kccthemes.buzz/",  // Live demo link
        },
        {
          icon: <IoLogoGithub />,  // GitHub link (update if applicable)
          linkTitle: "GitHub",  // Title for the link
          link: "#",  // GitHub link (add the real GitHub link if available)
        },
      ],
    },{
      id: 2,
      imageTabMenu: "https://app.netlify.com/.netlify/images?url=https://d33wubrfki0l68.cloudfront.net/65b40524d9ab560008fc41ff/screenshot_2024-01-26-19-17-51-0000.webp&fit=cover&h=500&w=800",
      imageDetailsPage: "https://app.netlify.com/.netlify/images?url=https://d33wubrfki0l68.cloudfront.net/65b40524d9ab560008fc41ff/screenshot_2024-01-26-19-17-51-0000.webp&fit=cover&h=500&w=800",
      title: "Resao Professional Creative Theme",
      slug: "resao-professional-creative-theme",
      descTabMenu:
        "React ve tailwindcss kullanarak geliştirmiş olduğum çok kapsamlı, full responsive bir react projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          React ve Tailwind CSS kullanarak geliştirdiğim bu kapsamlı proje, modern web geliştirme tekniklerini ve en iyi uygulamaları içermektedir. Proje, kullanıcı deneyimini ön planda tutarak tasarlanmış ve tam responsive yapıya sahiptir.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Projenin Temel Özellikleri:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern ve şık kullanıcı arayüzü</li>
              <li>Tam responsive tasarım</li>
              <li>Performans optimizasyonu</li>
              <li>SEO dostu yapı</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks ve custom hooks kullanımı</li>
              <li>Tailwind CSS ile modern styling</li>
              <li>Komponent bazlı mimari</li>
              <li>Clean code prensiplerine uygun geliştirme</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss"],
      date: "2024",
      url: "https://resao.kccthemes.buzz/",
      githubUrl: "#",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://app.netlify.com/.netlify/images?url=https://d33wubrfki0l68.cloudfront.net/65b40524d9ab560008fc41ff/screenshot_2024-01-26-19-17-51-0000.webp&fit=cover&h=500&w=800",
      ],
      featuresList: [
        "Modern React Hooks Kullanımı",
        "Responsive Tasarım",
        "Tailwind CSS ile Stilllendirme",
        "Optimize Edilmiş Performans",
        "SEO Dostu Yapı"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://resao.kccthemes.buzz/",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "#",
        },
      ],
    },{
      id: 3,
      imageTabMenu: "https://i.imgur.com/WSxKlNL.png",
      imageDetailsPage: "https://i.imgur.com/WSxKlNL.png", 
      title: "Hekto E-commerce Theme",
      slug: "hekto-e-commerce-theme",
      descTabMenu: "React ve Tailwindcss kullanarak geliştirmiş olduğum e-commerce react uygulaması.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Modern bir e-ticaret deneyimi sunan bu proje, React ve Tailwind CSS kullanılarak geliştirilmiş kapsamlı bir web uygulamasıdır. Kullanıcı dostu arayüzü ve optimize edilmiş performansı ile öne çıkmaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Projenin Ana Özellikleri:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Ürün listeleme ve filtreleme</li>
              <li>Alışveriş sepeti yönetimi</li>
              <li>Kullanıcı hesap işlemleri</li>
              <li>Ödeme sistemi entegrasyonu</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Redux Toolkit ile state yönetimi</li>
              <li>Responsive tasarım</li>
              <li>Modern UI/UX prensipleri</li>
              <li>Performans optimizasyonu</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "redux toolkit", "tailwindcss"],
      date: "2024",
      url: "https://hekto-creative.netlify.app",
      githubUrl: "https://github.com/kececirecep/hekto-ecommerce",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/WSxKlNL.png",
      ],
      featuresList: [
        "Redux Toolkit ile State Yönetimi",
        "Responsive Tasarım",
        "Modern UI/UX",
        "Performans Optimizasyonu",
        "Kullanıcı Dostu Arayüz"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://hekto-creative.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/hekto-ecommerce",
        },
      ],
    },{
      id: 4,
      imageTabMenu: "https://i.imgur.com/BLyy1Zi.png",
      imageDetailsPage: "https://i.imgur.com/BLyy1Zi.png",
      title: "Lawyer Landing Page",
      slug: "lawyer-landing-page", 
      descTabMenu: "React ve Tailwind CSS kullanarak geliştirmiş olduğum full responsive bir react projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Modern ve profesyonel bir avukatlık bürosu landing page'i olan bu proje, React ve Tailwind CSS kullanılarak geliştirilmiş responsive bir web uygulamasıdır. Kullanıcı deneyimini ön planda tutan tasarımı ile hukuk büroları için ideal bir çözüm sunmaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern ve profesyonel tasarım</li>
              <li>Tam responsive yapı</li>
              <li>Optimize edilmiş performans</li>
              <li>SEO dostu yapı</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks kullanımı</li>
              <li>Tailwind CSS ile stil yönetimi</li>
              <li>Modern component yapısı</li>
              <li>Hızlı yükleme süreleri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss"],
      date: "2024",
      url: "https://lawyer-creative-page.netlify.app",
      githubUrl: "https://github.com/kececirecep/Lawyer-Landing-Page",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/BLyy1Zi.png",
      ],
      featuresList: [
        "Modern Tasarım",
        "Responsive Yapı",
        "Optimize Performans",
        "SEO Dostu",
        "Kolay Özelleştirme"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://lawyer-creative-page.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/Lawyer-Landing-Page",
        },
      ],
    },
    {
      id: 5,
      imageTabMenu: "https://i.imgur.com/g45POw4.png",
      imageDetailsPage: "https://i.imgur.com/g45POw4.png",
      title: "Argencis Landing Page",
      slug: "argencis-landing-page", 
      descTabMenu: "React ve tailwindcss kullanarak geliştirmiş olduğum full responsive bir react projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Argencis Landing Page, React ve Tailwind CSS kullanılarak geliştirilmiş modern ve responsive bir web projesidir. Kullanıcı dostu arayüzü ve optimize edilmiş performansı ile öne çıkan bu proje, kurumsal web siteleri için ideal bir çözüm sunmaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern ve profesyonel tasarım</li>
              <li>Tam responsive yapı</li>
              <li>Optimize edilmiş performans</li>
              <li>SEO dostu yapı</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks kullanımı</li>
              <li>Tailwind CSS ile stil yönetimi</li>
              <li>Modern component yapısı</li>
              <li>Hızlı yükleme süreleri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss", "landing page"],
      date: "2024",
      url: "https://argencis-creative.netlify.app",
      githubUrl: "https://github.com/kececirecep/argencis-creative-landing",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/g45POw4.png",
      ],
      featuresList: [
        "Modern Tasarım",
        "Responsive Yapı", 
        "Optimize Performans",
        "SEO Dostu",
        "Kolay Özelleştirme"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://argencis-creative.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/argencis-creative-landing",
        },
      ],
    },
    {
      id: 6,
      imageTabMenu: "https://i.imgur.com/HXxKCx1.png",
      imageDetailsPage: "https://i.imgur.com/HXxKCx1.png", 
      title: "Creativo",
      slug: "creativo",
      descTabMenu: "React ve tailwindcss kullanarak geliştirmiş olduğum full responsive bir react projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Modern ve profesyonel bir tasarıma sahip Creativo, React ve Tailwind CSS kullanılarak geliştirilmiş kapsamlı bir web projesidir. Kullanıcı deneyimini ön planda tutan bu proje, tam responsive yapısı ve optimize edilmiş performansı ile öne çıkmaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern ve profesyonel tasarım</li>
              <li>Tam responsive yapı</li>
              <li>Optimize edilmiş performans</li>
              <li>SEO dostu yapı</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks kullanımı</li>
              <li>Tailwind CSS ile stil yönetimi</li>
              <li>Modern component yapısı</li>
              <li>Hızlı yükleme süreleri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss", "creative page"],
      date: "2024",
      url: "https://design-creative.netlify.app",
      githubUrl: "https://github.com/kececirecep/Creative",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/HXxKCx1.png",
      ],
      featuresList: [
        "Modern Tasarım",
        "Responsive Yapı",
        "Optimize Performans", 
        "SEO Dostu",
        "Kolay Özelleştirme"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://design-creative.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/Creative",
        },
      ],
    },
    {
      id: 7,
      imageTabMenu: "https://i.imgur.com/MiuFtqF.png",
      imageDetailsPage: "https://i.imgur.com/MiuFtqF.png", 
      title: "Banking Landing Page",
      slug: "banking-landing-page",
      descTabMenu: "React ve tailwindcss kullanarak geliştirmiş olduğum full responsive bir react projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Modern ve profesyonel bir landing page olan Banking Landing Page, React ve Tailwind CSS kullanılarak geliştirilmiş kapsamlı bir web uygulamasıdır. Kullanıcı deneyimini en üst düzeyde tutmak için tasarlanmış bu tema, bankacılık ve finans sektörü için ideal bir çözüm sunmaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern ve profesyonel tasarım</li>
              <li>Tam responsive yapı</li>
              <li>Optimize edilmiş performans</li>
              <li>SEO dostu yapı</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks kullanımı</li>
              <li>Tailwind CSS ile stil yönetimi</li>
              <li>Modern component yapısı</li>
              <li>Hızlı yükleme süreleri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss", "landing page"],
      date: "2024",
      url: "https://banking-landing-page.netlify.app",
      githubUrl: "https://github.com/kececirecep/Banking-Website-Landing-Page",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/MiuFtqF.png",
      ],
      featuresList: [
        "Modern Tasarım",
        "Responsive Yapı", 
        "Optimize Performans",
        "SEO Dostu",
        "Kolay Özelleştirme"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://banking-landing-page.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/Banking-Website-Landing-Page",
        },
      ],
    },
    {
      id: 8,
      imageTabMenu: "https://i.imgur.com/FQPgfu7.png",
      imageDetailsPage: "https://i.imgur.com/FQPgfu7.png", 
      title: "Blog Template",
      slug: "blog-template",
      descTabMenu: "React ve Tailwind CSS kullanarak geliştirdiğim full responsive basit bir blog projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Modern ve sade bir blog teması olan bu proje, React ve Tailwind CSS kullanılarak geliştirilmiş basit bir web uygulamasıdır. Kullanıcı deneyimini ön planda tutan responsive tasarımı ile blog yazılarının kolay okunabilirliğini sağlamaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Sade ve modern tasarım</li>
              <li>Kolay okunabilir blog yazıları</li>
              <li>Responsive yapı</li>
              <li>Hızlı yükleme süreleri</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks kullanımı</li>
              <li>Tailwind CSS ile stil yönetimi</li>
              <li>Basit ve anlaşılır kod yapısı</li>
              <li>Optimize edilmiş performans</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss", "blog"],
      date: "2024",
      url: "https://kececi-portfolio-blog.netlify.app",
      githubUrl: "https://github.com/kececirecep/blogPortfoliio",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/FQPgfu7.png",
      ],
      featuresList: [
        "Modern Blog Tasarımı",
        "Responsive Yapı",
        "Kolay Kullanım",
        "Hızlı Yükleme",
        "Sade Arayüz"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://kececi-portfolio-blog.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/blogPortfoliio",
        },
      ],
    },
    {
      id: 9,
      imageTabMenu: "https://i.imgur.com/K9qsBgM.png",
      imageDetailsPage: "https://i.imgur.com/K9qsBgM.png", 
      title: "Youtube Video List",
      slug: "youtube-video-list",
      descTabMenu: "React ve tailwindcss kullanarak geliştirmiş olduğum full responsive bir react projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Youtube Video List projesi, React ve Tailwind CSS kullanılarak geliştirilmiş modern ve responsive bir web uygulamasıdır. Kullanıcı dostu arayüzü ve optimize edilmiş performansı ile video içeriklerini listelemek ve görüntülemek için ideal bir çözüm sunar.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern ve kullanıcı dostu arayüz</li>
              <li>Responsive tasarım</li>
              <li>Video içerik yönetimi</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks kullanımı</li>
              <li>Tailwind CSS ile stil yönetimi</li>
              <li>Optimize edilmiş performans</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss"],
      date: "2024",
      url: "https://kececi-youtube.netlify.app",
      githubUrl: "https://github.com/kececirecep/youtube",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/K9qsBgM.png",
      ],
      featuresList: [
        "Modern Video Liste Tasarımı",
        "Responsive Yapı",
        "Kolay Kullanım",
        "Hızlı Yükleme",
        "Sade Arayüz"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://kececi-youtube.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/youtube",
        },
      ],
    },
    {
      id: 10,
      imageTabMenu: "https://i.imgur.com/GL1nqJN.png",
      imageDetailsPage: "https://i.imgur.com/GL1nqJN.png", 
      title: "Todo App",
      slug: "todo-app",
      descTabMenu: "React ve TailwindCSS kullanarak geliştirdiğim, tamamen duyarlı (responsive) bir Todo uygulaması.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          React ve TailwindCSS kullanarak geliştirdiğim bu Todo uygulaması, modern web teknolojilerini kullanarak kullanıcı dostu bir deneyim sunmayı hedeflemektedir. Farklı ekran boyutlarına uyum sağlayan responsive tasarımı ve performans odaklı yapısıyla dikkat çekmektedir.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Temel Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Görev ekleme ve düzenleme</li>
              <li>Görev tamamlama takibi</li>
              <li>Kategori bazlı filtreleme</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks yapısı</li>
              <li>TailwindCSS ile responsive tasarım</li>
              <li>Yerel depolama entegrasyonu</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss"],
      date: "2024",
      url: "https://kececi-todo.netlify.app",
      githubUrl: "https://github.com/kececirecep/todo",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/GL1nqJN.png",
      ],
      featuresList: [
        "Modern Görev Yönetimi",
        "Responsive Tasarım",
        "Kullanıcı Dostu Arayüz",
        "Performans Optimizasyonu",
        "Kolay Kullanım"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://kececi-todo.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/todo",
        },
      ],
    },
    {
      id: 11,
      imageTabMenu: "https://i.imgur.com/l4VHgxh.png",
      imageDetailsPage: "https://i.imgur.com/l4VHgxh.png", 
      title: "Portfolio",
      slug: "portfolio",
      descTabMenu: "React ve tailwindcss kullanarak geliştirmiş olduğum full responsive bir react projesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Modern ve profesyonel bir portfolio projesi olarak geliştirdiğim bu uygulama, React ve Tailwind CSS kullanılarak oluşturulmuştur. Tamamen responsive tasarımı ile her cihazda kusursuz görüntüleme sağlar.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern ve şık tasarım</li>
              <li>Responsive yapı</li>
              <li>Kolay gezinme</li>
              <li>Optimize edilmiş performans</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks yapısı</li>
              <li>Tailwind CSS ile stil yönetimi</li>
              <li>Modern component mimarisi</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss"],
      date: "2024",
      url: "https://kececi-portfolio-v2.netlify.app",
      githubUrl: "https://github.com/kececirecep/portfolio-one",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/l4VHgxh.png",
      ],
      featuresList: [
        "Modern Tasarım",
        "Responsive Yapı",
        "Optimize Performans",
        "Kullanıcı Dostu Arayüz",
        "Kolay Özelleştirme"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://kececi-portfolio-v2.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/portfolio-one",
        },
      ],
    },
    {
      id: 12,
      imageTabMenu: "https://i.imgur.com/zuGTlR1.png",
      imageDetailsPage: "https://i.imgur.com/zuGTlR1.png",
      title: "Number Matching Game",
      slug: "number-matching-game", 
      descTabMenu: "React ve tailwindcss kullanarak geliştirmiş olduğum numara eşleştirme oyunu.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          React ve Tailwind CSS kullanılarak geliştirilmiş eğlenceli bir numara eşleştirme oyunu. Oyuncular belirli bir süre içinde eşleşen numaraları bulmaya çalışır. Modern ve kullanıcı dostu arayüzü ile hem eğlenceli hem de öğretici bir deneyim sunar.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Oyun Özellikleri:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Zamanlayıcı sistemi</li>
              <li>Puan hesaplama</li>
              <li>Seviye sistemi</li>
              <li>Ses efektleri</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks yapısı</li>
              <li>Responsive tasarım</li>
              <li>Modern animasyonlar</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss"],
      date: "2024",
      url: "https://kececi-number-matching-game.netlify.app",
      githubUrl: "https://github.com/kececirecep/number-matching-game",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/zuGTlR1.png",
      ],
      featuresList: [
        "Zamanlayıcı Sistemi",
        "Puan Hesaplama",
        "Seviye Sistemi",
        "Modern Arayüz",
        "Ses Efektleri"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://kececi-number-matching-game.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/number-matching-game",
        },
      ],
    },
    {
      id: 13,
      imageTabMenu: "https://i.imgur.com/1dpkbu5.png",
      imageDetailsPage: "https://i.imgur.com/1dpkbu5.png",
      title: "Portfolio v2",
      slug: "portfolio-v2", 
      descTabMenu: "React ve tailwindcss kullanarak geliştirmiş olduğum modern bir portfolio sitesi.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Modern ve profesyonel bir portfolio sitesi olan Portfolio v2, React ve Tailwind CSS kullanılarak geliştirilmiş kapsamlı bir web uygulamasıdır. Kullanıcı deneyimini en üst düzeyde tutmak için tasarlanmış bu site, kişisel portfolyo için ideal bir çözüm sunmaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Modern ve şık tasarım</li>
              <li>Koyu/Açık tema desteği</li>
              <li>Responsive yapı</li>
              <li>Özelleştirilebilir bileşenler</li>
            </ul>
          </li>
          <li>Teknik Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>React hooks yapısı</li>
              <li>Tailwind CSS ile stil yönetimi</li>
              <li>SEO optimizasyonu</li>
              <li>Yüksek performans</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["react", "tailwindcss"],
      date: "2024",
      url: "https://kececi-number-matching-game.netlify.app",
      githubUrl: "https://github.com/kececirecep/portfolio-theme",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
        { image: "/images/projects/tags/24x24.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/1dpkbu5.png",
      ],
      featuresList: [
        "Modern Tasarım",
        "Responsive Yapı",
        "Koyu/Açık Tema",
        "SEO Optimizasyonu",
        "Yüksek Performans"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "Canlı Demo",
          link: "https://kececi-number-matching-game.netlify.app",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "GitHub",
          link: "https://github.com/kececirecep/portfolio-theme",
        },
      ],
    },
    {
      id: 14,
      imageTabMenu: "https://i.imgur.com/Rv9NoFN.png",
      imageDetailsPage: "https://i.imgur.com/Rv9NoFN.png", 
      title: "Star Wars Yoda",
      slug: "star-wars-yoda",
      descTabMenu: "CSS Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu CSS alıştırmasında, Star Wars'un ikonik karakteri Yoda'yı saf CSS kullanarak stilize edilmiş bir versiyonunu oluşturdum. Proje, CSS şekillendirme ve animasyon tekniklerine odaklanmaktadır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Gelişmiş CSS teknikleri ile animasyonlar</li>
              <li>Transform efektleri</li>
              <li>Responsive tasarım uyumluluğu</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Saf CSS kullanımı</li>
              <li>Karmaşık şekil oluşturma</li>
              <li>CSS animasyonları</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/BayjPEV",
      githubUrl: "https://codepen.io/kececi/pen/BayjPEV",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/Rv9NoFN.png",
      ],
      featuresList: [
        "CSS Animasyonları",
        "Responsive Tasarım",
        "Yaratıcı CSS Kullanımı",
        "Saf CSS ile Geliştirme",
        "İnteraktif Efektler"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/BayjPEV",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/BayjPEV",
        },
      ],
    },
    {
      id: 15,
      imageTabMenu: "https://i.imgur.com/66diiiU.png",
      imageDetailsPage: "https://i.imgur.com/66diiiU.png", 
      title: "Css Bear",
      slug: "css-bear",
      descTabMenu: "Css Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu CSS alıştırmasında, sadece CSS kullanarak stilize edilmiş bir ayı tasarımı oluşturdum. Proje, ayı illüstrasyonunu oluşturmak için CSS şekilleri, animasyonlar ve geçişleri kullanmaya odaklanıyor.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Saf CSS ile karmaşık şekiller ve animasyonlar</li>
              <li>Responsive tasarım uyumluluğu</li>
              <li>İnteraktif kullanıcı deneyimi</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS dönüşüm efektleri</li>
              <li>Özel animasyonlar</li>
              <li>Gelişmiş seçici kullanımı</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/GRgjydM",
      githubUrl: "https://codepen.io/kececi/pen/GRgjydM",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/66diiiU.png",
      ],
      featuresList: [
        "Saf CSS Tasarım",
        "CSS Animasyonları",
        "Responsive Düzen",
        "İnteraktif Efektler",
        "Yaratıcı CSS Kullanımı"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/GRgjydM",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/GRgjydM",
        },
      ],
    },
    {
      id: 16,
      imageTabMenu: "https://i.imgur.com/MtruVGF.png",
      imageDetailsPage: "https://i.imgur.com/MtruVGF.png", 
      title: "CSS Pizza",
      slug: "css-pizza",
      descTabMenu: "CSS Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu CSS alıştırmasında, sadece CSS kullanarak bir pizza illüstrasyonu oluşturdum. Bu proje, CSS ile karmaşık şekiller ve animasyonlar oluşturma becerilerimi göstermektedir.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS şekilleri ve pseudo-elementler ile pizza illüstrasyonu</li>
              <li>Eğlenceli ve interaktif pizza tasarımı</li>
              <li>Saf CSS kullanımı</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS dönüşüm efektleri</li>
              <li>Özel animasyonlar</li>
              <li>Gelişmiş CSS seçicileri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/oNggYoL",
      githubUrl: "https://codepen.io/kececi/pen/oNggYoL",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/MtruVGF.png",
      ],
      featuresList: [
        "CSS Şekilleri",
        "CSS Animasyonları",
        "Yaratıcı İllüstrasyonlar",
        "İnteraktif Tasarım",
        "Saf CSS Kullanımı"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/oNggYoL",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/oNggYoL",
        },
      ],
    },
    {
      id: 17,
      imageTabMenu: "https://i.imgur.com/3lIGO6f.png",
      imageDetailsPage: "https://i.imgur.com/3lIGO6f.png", 
      title: "Pokémon CSS Alıştırmaları",
      slug: "pokemon-css-alistirmalari",
      descTabMenu: "CSS kullanarak Pokémon logosu ve tasarım öğelerini yeniden oluşturduğum eğlenceli bir CSS alıştırması.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu projede, sadece CSS kullanarak Pokémon logosunu ve tasarım öğelerini yeniden oluşturdum. Bu çalışma, CSS şekilleri, animasyonlar ve geçişler konusundaki yeteneklerimi sergiliyor.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS ile Pokémon logo tasarımı</li>
              <li>İnteraktif animasyonlar</li>
              <li>Saf CSS kullanımı</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS dönüşüm efektleri</li>
              <li>Özel animasyonlar</li>
              <li>Gelişmiş CSS seçicileri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/rNaBxyo",
      githubUrl: "https://codepen.io/kececi/pen/rNaBxyo",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/3lIGO6f.png",
      ],
      featuresList: [
        "CSS Şekilleri",
        "CSS Animasyonları",
        "Logo Tasarımı",
        "İnteraktif Öğeler",
        "Saf CSS Kullanımı"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/rNaBxyo",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/rNaBxyo",
        },
      ],
    },
    {
      id: 18,
      imageTabMenu: "https://i.imgur.com/eooxIqH.png",
      imageDetailsPage: "https://i.imgur.com/eooxIqH.png", 
      title: "CSS Dondurma",
      slug: "css-dondurma",
      descTabMenu: "CSS Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu CSS alıştırmasında, sadece CSS kullanarak eğlenceli ve interaktif bir dondurma tasarımı oluşturdum. Bu proje, CSS animasyonları ve geçişleri konusundaki becerilerimi sergiliyor.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS şekilleri ve gradyanlar ile dondurma külahı tasarımı</li>
              <li>Dinamik animasyon efektleri</li>
              <li>Saf CSS kullanımı</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS dönüşüm efektleri</li>
              <li>Özel animasyonlar</li>
              <li>İnteraktif tasarım</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/aMYRvy",
      githubUrl: "https://codepen.io/kececi/pen/aMYRvy",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/eooxIqH.png",
      ],
      featuresList: [
        "CSS Şekilleri",
        "CSS Animasyonları",
        "İnteraktif Tasarım",
        "Saf CSS Kullanımı",
        "Görsel Efektler"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/aMYRvy",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/aMYRvy",
        },
      ],
    },
    {
      id: 19,
      imageTabMenu: "https://i.imgur.com/pzHO7YF.png",
      imageDetailsPage: "https://i.imgur.com/pzHO7YF.png", 
      title: "CSS Kedi",
      slug: "css-kedi",
      descTabMenu: "CSS Alıştırmaları - Kedi Tasarımı",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu CSS alıştırmasında, yalnızca HTML ve CSS kullanarak basit ama sevimli bir kedi tasarımı oluşturdum. Proje, CSS şekilleri oluşturmayı ve bunları eğlenceli bir efekt için canlandırmayı içeriyor.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS şekilleri ve gradyanlar ile kedi tasarımı</li>
              <li>Dinamik animasyon efektleri</li>
              <li>Saf CSS kullanımı</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS dönüşüm efektleri</li>
              <li>Özel animasyonlar</li>
              <li>İnteraktif tasarım</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/yreJmQ",
      githubUrl: "https://codepen.io/kececi/pen/yreJmQ",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/pzHO7YF.png",
      ],
      featuresList: [
        "CSS Şekilleri",
        "CSS Animasyonları",
        "İnteraktif Tasarım",
        "Saf CSS Kullanımı",
        "Görsel Efektler"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/yreJmQ",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/yreJmQ",
        },
      ],
      id: 21,
      imageTabMenu: "https://i.imgur.com/w0jzX4I.png",
      imageDetailsPage: "https://i.imgur.com/w0jzX4I.png", 
      title: "CSS Kutu Alıştırması",
      slug: "css-kutu-alistirmasi",
      descTabMenu: "CSS ile basit ve etkileşimli bir kutu tasarımı alıştırması",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu CSS alıştırmasında, yalnızca CSS kullanarak etkileşimli hover efektlerine sahip basit bir kutu tasarımı oluşturdum. Temiz bir tasarım yaklaşımıyla, CSS'in düzen ve animasyon özelliklerini kullanmaya odaklandım.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Saf CSS ile etkileşimli hover efektleri</li>
              <li>Yumuşak geçiş animasyonları</li>
              <li>Minimalist tasarım yaklaşımı</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS transitions kullanımı</li>
              <li>Renk ve gölge efektleri</li>
              <li>Hover durumu yönetimi</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/GeLpJJ?editors=0010",
      githubUrl: "https://codepen.io/kececi/pen/GeLpJJ?editors=0010",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/w0jzX4I.png",
      ],
      featuresList: [
        "Etkileşimli Hover Efektleri",
        "CSS Geçiş Animasyonları",
        "Basit ve Şık Tasarım",
        "Saf CSS Kullanımı",
        "Görsel Efektler"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/GeLpJJ?editors=0010",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/GeLpJJ?editors=0010",
        },
      ],
    }, 
    {
      id: 20,
      imageTabMenu: "https://i.imgur.com/bVEcMBz.png",
      imageDetailsPage: "https://i.imgur.com/bVEcMBz.png", 
      title: "CSS Domuz",
      slug: "css-domuz",
      descTabMenu: "CSS ile domuz şeklinde eğlenceli bir tasarım alıştırması. Sadece CSS kullanarak oluşturulmuş interaktif bir çalışma.",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu CSS alıştırması, yalnızca CSS kullanarak domuz şeklinde bir tasarım oluşturmayı göstermektedir. CSS konumlandırma, dönüşümler ve animasyonlar kullanılarak eğlenceli ve interaktif bir tasarım elde edilmiştir.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Saf CSS ile oluşturulmuş domuz tasarımı</li>
              <li>İnteraktif animasyonlar</li>
              <li>Görsel kullanmadan pure CSS çözümü</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS transforms ve transitions kullanımı</li>
              <li>Kompleks şekil oluşturma teknikleri</li>
              <li>CSS pozisyonlama yöntemleri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/bZvJjz",
      githubUrl: "https://codepen.io/kececi/pen/bZvJjz",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/bVEcMBz.png",
      ],
      featuresList: [
        "Saf CSS ile Şekil Tasarımı",
        "İnteraktif Animasyonlar",
        "Görsel Kullanılmadan Oluşturma",
        "CSS Dönüşümleri",
        "Pozisyonlama Teknikleri"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/bZvJjz",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/bZvJjz",
        },
      ],
    },
    {
      id: 21,
      imageTabMenu: "https://i.imgur.com/WiTQ5GH.png",
      imageDetailsPage: "https://i.imgur.com/WiTQ5GH.png", 
      title: "Pokemon",
      slug: "pokemon",
      descTabMenu: "CSS Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu CSS alıştırması, sadece CSS kullanarak Pokemon temalı bir tasarım oluşturmayı göstermektedir. CSS pozisyonlama, şekiller ve animasyon efektlerini kullanarak eğlenceli ve etkileyici bir tasarım yaratmaya odaklanır.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Görsel kullanmadan CSS ile Pokemon karakteri tasarımı</li>
              <li>İnteraktif animasyonlar ve geçiş efektleri</li>
              <li>Detaylı şekil ve form oluşturma teknikleri</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS transforms ve transitions kullanımı</li>
              <li>Kompleks karakter şekillendirme</li>
              <li>CSS pozisyonlama teknikleri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/wQPGbP",
      githubUrl: "https://codepen.io/kececi/pen/wQPGbP",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/WiTQ5GH.png",
      ],
      featuresList: [
        "CSS Karakter Tasarımı",
        "İnteraktif Animasyonlar",
        "Görsel Kullanmadan Oluşturma",
        "CSS Dönüşümleri",
        "Pozisyonlama Teknikleri"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/wQPGbP",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/wQPGbP",
        },
      ],
    },
    {
      id: 22,
      imageTabMenu: "https://i.imgur.com/H5JzrKk.png",
      imageDetailsPage: "https://i.imgur.com/H5JzrKk.png",
      title: "Login Page",
      slug: "login-page", 
      descTabMenu: "CSS Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu proje, sadece CSS kullanılarak tasarlanmış modern bir giriş sayfasıdır. Tasarım, CSS ile şekillendirilmiş temel form elemanlarına sahip temiz bir arayüz sunarak, etkileşimli ve kullanıcı dostu giriş formlarının nasıl oluşturulacağını göstermektedir.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS Grid kullanılarak özel giriş formu düzeni</li>
              <li>Hover efektleri ve geçişlerle etkileşimli elementler</li>
              <li>Modern ve temiz tasarım yaklaşımı</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS Grid sistem implementasyonu</li>
              <li>Form elemanları stillemesi</li>
              <li>Geçiş animasyonları</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/rNxdGwO",
      githubUrl: "https://codepen.io/kececi/pen/rNxdGwO",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/H5JzrKk.png",
      ],
      featuresList: [
        "CSS Grid Düzeni",
        "Etkileşimli Formlar",
        "Hover Efektleri",
        "Modern Tasarım",
        "Geçiş Animasyonları"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/rNxdGwO",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/rNxdGwO",
        },
      ],
    },
    {
      id: 23,
      imageTabMenu: "https://i.imgur.com/ocECzLz.png",
      imageDetailsPage: "https://i.imgur.com/ocECzLz.png", 
      title: "Google Error Page",
      slug: "google-error-page",
      descTabMenu: "CSS Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu proje, Google'ın 404 hata sayfasının CSS ile yeniden oluşturulmasıdır. Tasarım, gerçek sayfanın basitliğini ve işlevselliğini taklit ederek, düzen teknikleri, tipografi ve duyarlı tasarım gibi CSS becerilerini göstermektedir.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>İçeriği merkezlemek için CSS Flexbox kullanımı</li>
              <li>Orijinal Google hata sayfasına uygun tipografi</li>
              <li>Farklı ekran boyutlarına uyumlu duyarlı tasarım</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Flexbox düzen sistemi</li>
              <li>Google font entegrasyonu</li>
              <li>Responsive tasarım prensipleri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/KEmjMg",
      githubUrl: "https://codepen.io/kececi/pen/KEmjMg",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/ocECzLz.png",
      ],
      featuresList: [
        "CSS Flexbox Kullanımı",
        "Tipografi Tasarımı",
        "Duyarlı Düzen",
        "Google Tasarım Öğeleri",
        "Hata Sayfası Tasarımı"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/KEmjMg",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/KEmjMg",
        },
      ],
    },
    {
      id: 24,
      imageTabMenu: "https://i.imgur.com/zPiCgky.png",
      imageDetailsPage: "https://i.imgur.com/zPiCgky.png", 
      title: "CSS Menu",
      slug: "css-menu",
      descTabMenu: "CSS Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu projede CSS kullanarak bir menü düzeni oluşturdum. Flexbox ve hover efektleri gibi çeşitli CSS tekniklerini kullanarak duyarlı ve etkileşimli bir menü tasarımı gerçekleştirdim.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Flexbox kullanarak duyarlı tasarım</li>
              <li>Menü öğelerinde etkileşimli hover efektleri</li>
              <li>Modern ve sade menü düzeni</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS Flexbox düzen sistemi</li>
              <li>Hover ve transition efektleri</li>
              <li>Responsive tasarım prensipleri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/LavVzr",
      githubUrl: "https://codepen.io/kececi/pen/LavVzr",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/zPiCgky.png",
      ],
      featuresList: [
        "Duyarlı Tasarım",
        "Etkileşimli Hover Efektleri",
        "Flexbox Düzeni",
        "Modern Menü Tasarımı",
        "CSS Animasyonları"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/LavVzr",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/LavVzr",
        },
      ],
    },
    {
      id: 25,
      imageTabMenu: "https://i.imgur.com/NV4nbF3.png",
      imageDetailsPage: "https://i.imgur.com/NV4nbF3.png", 
      title: "CSS Efektif Animasyon",
      slug: "css-efektif-animasyon",
      descTabMenu: "CSS Alıştırmaları",
      content: `
        <p class="text-gray-500 mt-6 mb-6 leading-7 dark:text-darkTextChild">
          Bu proje, öğelere canlılık katan çeşitli CSS animasyon tekniklerini göstermektedir. Kullanıcı deneyimini geliştiren akıcı geçişler ve etkileşimli animasyonlar içerir.
        </p>
        <ol class="space-y-4 text-gray-500 list-decimal list-inside dark:text-darkTextChild my-6">
          <li>Öne Çıkan Özellikler:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>Çeşitli CSS animasyon teknikleri</li>
              <li>Etkileşimli hover ve tıklama animasyonları</li>
              <li>UI öğeleri için akıcı ve yumuşak geçişler</li>
            </ul>
          </li>
          <li>Teknik Detaylar:
            <ul class="ps-5 mt-2 space-y-2 list-disc list-inside">
              <li>CSS keyframe animasyonları</li>
              <li>Transform ve transition özellikleri</li>
              <li>Etkileşimli animasyon tetikleyicileri</li>
            </ul>
          </li>
        </ol>
      `,
      tags: ["css"],
      date: "2024",
      url: "https://codepen.io/kececi/pen/MWWxjMv",
      githubUrl: "https://codepen.io/kececi/pen/MWWxjMv",
      tagsHead: "Teknolojiler",
      technologiesTags: [
        { image: "/images/projects/tags/css.webp" },
      ],
      additionalImages: [
        "https://i.imgur.com/NV4nbF3.png",
      ],
      featuresList: [
        "CSS Animasyonları",
        "Etkileşimli Efektler",
        "Yumuşak Geçişler",
        "Keyframe Animasyonları",
        "Dönüşüm Efektleri"
      ],
      linkHead: "Bağlantılar",
      links: [
        {
          icon: <HiLink />,
          linkTitle: "CodePen Demo",
          link: "https://codepen.io/kececi/pen/MWWxjMv",
        },
        {
          icon: <IoLogoGithub />,
          linkTitle: "CodePen",
          link: "https://codepen.io/kececi/pen/MWWxjMv",
        },
      ],
    }
 
  ],
};

export default projectsData;