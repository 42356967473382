// React and external libraries
import React from 'react';
import { useSelector } from 'react-redux';
import { motion } from "framer-motion";
import { FaCoffee, FaBookReader, FaPlane, FaGuitar, FaCameraRetro } from 'react-icons/fa';

// Components
import Helmet from "../components/Helmet/Helmet"; // For managing document head
import Heading from "../components/Titles/Heading"; // For heading titles
import Description from "../components/Titles/Description"; // For descriptions
import { FaComputer } from 'react-icons/fa6';

const About = () => {
  const darkMode = useSelector((state) => state.theme.theme === "dark"); // Get current theme from Redux

  return (
    <>
      <Helmet title="About Me | Personal Portfolio" desc="Learn about my experiences, skills, and passions as a software developer." />

      {/* Hero Section */}
      <section className="relative flex items-center justify-center h-[300px]">
        {/* Background image */}
        <div className="absolute inset-0 bg-cover bg-center rounded-t-lg" style={{ backgroundImage: 'url(/images/about/about-bg.jpg)' }}></div>

        {/* Black overlay */}
        <div className="absolute inset-0 bg-black opacity-50 rounded-t-lg"></div>

        {/* Content */}
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="relative text-center space-y-2 text-white z-10"
        >
          <Heading>Altay Kececi</Heading>
          <Description className="text-lg text-gray-100">
            Front-end Developer
          </Description>
        </motion.div>
      </section>

      {/* About Me Section */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`${darkMode ? 'bg-darkBgMain text-darkTextMain' : 'bg-white text-gray-900'} p-7 rounded-b-lg rounded-t-none mb-10 shadow-lg`}
      >
        <div className="container mx-auto px-4 py-8">
          <div className="flex flex-col space-y-1.5 mb-8">
            <Heading>Altay Kececi</Heading>
            <Description>
              Front-End Geliştiricisi
            </Description>
          </div>

          <div className="mb-12">
            <p className="mb-4 text-lg leading-relaxed">
              Yazılım dünyasına olan ilgim, problem çözme ve yenilikçi fikirler yaratma arzumla başladı. Bu alanda uzun yıllardır çalışıyorum ve her gün yeni teknolojiler öğrenerek kendimi geliştiriyorum.
            </p>
            <p className="mb-4 text-lg leading-relaxed">
            HTML, CSS, SCSS, Tailwind, Bootstrap ve React.js gibi modern web teknolojilerine odaklanarak, kullanıcı dostu ve duyarlı web uygulamaları geliştirmeye çalışıyorum. Amacım, kullanıcı deneyimini iyileştiren verimli ve yenilikçi çözümler üretmek.
            </p>
          </div>

          <div className="mb-12">
            <h2 className="text-2xl font-bold mb-4">Hobilerim ve İlgi Alanlarım</h2>
            <ul className="list-none space-y-4">
              <li className="flex items-center">
                <FaBookReader className="mr-3 text-xl text-blue-500" />
                <span>Öğrenme: Özellikle yeni teknolojileri öğrenmek benim için en keyifli aktivite.</span>
              </li>
              <li className="flex items-center">
                <FaCameraRetro className="mr-3 text-xl text-green-500" />
                <span>Sinema: Farklı türlerdeki filmleri izlemeyi ve film analizleri yapmayı seviyorum</span>
              </li>
              <li className="flex items-center">
                <FaGuitar className="mr-3 text-xl text-red-500" />
                <span>Müzik: Alternatif Rock ve Rap dinlemeyi seviyorum</span>
              </li>
              <li className="flex items-center">
                <FaComputer className="mr-3 text-xl text-yellow-600" />
                <span>Oyun: Güncel oyunları oynamayı seviyorum</span>
              </li>
            </ul>
          </div>
   
          <div className="mt-12">
            <Heading>İletişim</Heading>
            <p className="mt-4 text-lg leading-relaxed">
            Herhangi bir sorunuz veya işbirliği teklifiniz varsa, benimle iletişime geçmekten çekinmeyin. En kısa sürede geri dönüş yapacağım! <a href="altaykececi.dev@gmail.com" className="text-blue-500 hover:underline">altaykececi.dev@gmail.com</a>.
            </p>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default About;