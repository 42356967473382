// React and external libraries
import React, { useRef } from "react";
import { useForm } from "react-hook-form"; 
import { motion } from "framer-motion";  
import { RiMailSendLine } from "react-icons/ri";  
import toast from 'react-hot-toast';

// Components
import Helmet from "../components/Helmet/Helmet";  
import ContactCard from "../components/Contact/ContactCard";  
import Input from "../components/FormComponents/Input";  
import Textarea from "../components/FormComponents/Textarea"; 
import ContactButton from "../components/FormComponents/ContactButton";  
import Heading from "../components/Titles/Heading";   
import Description from "../components/Titles/Description";  

// Data
import contactData from "../components/Contact/data/contact"; // Data for the contact cards
import helmet from "../components/Helmet/data/helmet"; // Meta data for the page

import emailjs from '@emailjs/browser';

const Contact = () => {

  const form = useRef();

  const sendEmail = (formData) => {
    emailjs
      .sendForm('service_emqnxsc', 'template_urahwpw', form.current, {
        publicKey: 'S5N0lDNbREuBSuFY3',
      })
      .then(
        () => {
          toast.success('Mesajınız başarıyla gönderildi!');
          reset();
        },
        (error) => {
          toast.error('Mesaj gönderilemedi. Lütfen tekrar deneyin.');
          console.log('FAILED...', error.text);
        },
      );
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm(); // React Hook Form setup

 
  return (
    <>
      {/* Helmet component to set the page title and meta description */}
      <Helmet title={helmet.contact.title} desc={helmet.contact.desc} />

      {/* Motion wrapper for animations */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="bg-white p-7 rounded-lg mb-10 dark:bg-darkBgMain"
      >
        {/* Google Maps embed */}
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48321.03731847687!2d29.3812370707257!3d40.80456909310607!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cad8ae0d8aa779%3A0xc50b56d262cb7062!2sGebze%2C%20Kocaeli!5e0!3m2!1str!2str!4v1734801850076!5m2!1str!2str"
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className="w-full mb-6 rounded-lg h-44"
        ></iframe>

        {/* Section heading and description */}
        <div className="flex flex-col space-y-1.5 ">
          <Heading>İletişime Geçin</Heading>
          <Description>
            Aşağıdaki formu doldurun, size en kısa sürede geri dönüş yapacağım.
          </Description>
        </div>

        {/* Contact cards for various contact methods */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-8">
          {contactData &&
            contactData.map((item, index) => (
              <ContactCard key={index} item={item} />
            ))}
        </div>

        {/* Contact form */}
        <form ref={form} className="" onSubmit={handleSubmit(sendEmail)}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-0 md:gap-4">
            <Input
              label="Ad"
              placeholder="Adınızı girin"
              name="firstName"
              register={register("firstName", {
                required: "Ad alanı zorunludur.",
              })}
              error={errors.firstName}
            />
            <Input
              label="Soyad"
              placeholder="Soyadınızı girin"
              name="lastName"
              register={register("lastName", {
                required: "Soyad alanı zorunludur.",
              })}
              error={errors.lastName}
            />
          </div>
          <Input
            label="E-posta"
            placeholder="E-posta adresinizi girin"
            name="email"
            register={register("email", {
              required: "E-posta alanı zorunludur.",
              pattern: {
                value: /\S+@\S+\.\S+/,
                message: "Geçersiz e-posta adresi.",
              },
            })}
            error={errors.email}
          />
          <Textarea
            label="Mesaj"
            placeholder="Mesajınızı girin"
            name="message"
            register={register("message", { required: "Mesaj alanı zorunludur." })}
            error={errors.message}
          />
          
          {/* Submit button */}
          <ContactButton type="submit">
            <RiMailSendLine /> <span className="text-sm">Gönder</span>
          </ContactButton>
        </form>
      </motion.div>
    </>
  );
};

export default Contact;