const experience = [
  {
    date: "2024 - Günümüz",
    image: "/images/about/experience/aksanteknoloji_logo.jpeg",
    title: "Frontend Developer",
    company: "Aksan Yazılım",
    desc: "Bootstrap ve Jquery kullanarak modern web uygulamaları geliştirdim. Tailwind CSS ile responsive tasarımlar oluşturup, Redux Toolkit ile state yönetimi sağladım. TypeScript ile tip güvenliği olan kod yazdım.",
    location: "Gebze, Kocaeli",
  },
  {
    date: "2022 - 2023", 
    image: "/images/about/experience/vagonsoft.png",
    title: "Frontend Developer",
    company: "Vagon Soft",
    desc: "E-ticaret platformları için özel admin panelleri ve kullanıcı arayüzleri tasarladım. REST API entegrasyonları yapıp, performans optimizasyonları gerçekleştirdim. Git ile versiyon kontrolü sağladım.",
    location: "Kartal, İstanbul",
  },
  {
    date: "2021 - 2022",
    image: "/images/about/experience/koctim.jpeg", 
    title: "Frontend Developer",
    company: "Koçtim Group",
    desc: "Led the development of full-stack web applications, managing both front-end and back-end components. Introduced best practices for code quality, testing, and deployment, improving overall project efficiency.",
    location: "Kartal, İstanbul",
  },
  {
    date: "2019 - 2021",
    image: "/images/about/experience/bozkurt.jpeg",
    title: "Frontend Developer", 
    company: "Bozkurt Yazılım",
    desc: "Angular framework'ü ile hastane randevu sistemleri ve güzellik merkezi uygulamaları geliştirdim. Node.js backend servisleri yazıp, MongoDB veritabanı entegrasyonları gerçekleştirdim.",
    location: "Gebze, Kocaeli",
  },
];

export default experience;