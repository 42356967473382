// React and external libraries
import React from "react";

// Components
import Heading from "../Titles/Heading";
import Description from "../Titles/Description";

const AboutCard = () => {
  return (
    <div className="p-12 py-12 bg-white shadow rounded-lg flex flex-col md:grid md:grid-cols-3 md:items-center dark:bg-darkBgMain shadow-lg">
      <div className="order-1 md:order-none md:col-span-2">
        <Heading className="capitalize mb-2">
          Front-End Geliştirici
        </Heading>
        <Description className="leading-6">
          React.js ve modern web teknolojileri ile kullanıcı dostu web uygulamaları geliştiriyorum. Responsive tasarım ve performans konularında uzmanım. Yenilikçi çözümler üreterek projelere değer katmayı hedefliyorum.
        </Description>
      </div>
      <div className="order-0 md:order-none inline-flex justify-center mb-6 md:mb-0">
        <img src="/images/about/aboutSm.svg" alt="" className="w-[240px] h-[160px] rounded-lg" />
      </div>
    </div>
  );
};

export default AboutCard;
