const dots = [
  {
    x: 0.80,
    y: 0.55,
    content: "NZXT H510",
    url: "https://example.com/razer-deathadder-v2",
  },
  {
    x: 0.55,
    y: 0.44,
    content: "Corsair K95 RGB Platinum - Mechanical Keyboard",
    url: "https://example.com/corsair-k95-rgb-platinum",
  },
  {
    x: 0.23,
    y: 0.32,
    content: 'ASUS ROG Strix 27" - Gaming Monitor',
    url: "https://example.com/asus-rog-strix-monitor",
  },
  {
    x: 0.15,
    y: 0.72,
    content: "Apex 9 Mini FaZe Clan Series - Mechanical Keyboard",
    url: "https://example.com/apex-9-mini-faze-clan",
  },
  {
    x: 0.42,
    y: 0.60,
    content: "Logitech G Pro X - Wireless Gaming Headset",
    url: "https://example.com/logitech-g-pro-x",
  },
  {
    x: 0.43,
    y: 0.74,
    content: "SteelSeries Rival 3 - Gaming Mouse",
    url: "https://example.com/steelseries-rival-3",
  },
  {
    x: 0.55,
    y: 0.77,
    content: "BenQ Zowie XL2546 - Gaming Monitor",
    url: "https://example.com/benq-zowie-xl2546",
  },
];

const image = "/images/mysetup/1050x700.webp";

export { dots, image }; 