import React from "react";
import { FaAmazonPay, FaShoppingBasket } from "react-icons/fa";
import { BiLogoEtsy } from "react-icons/bi";
 
const workspaceData = {
  header: {
    title: "Tavsiye Ettiğim Ürünler",
    desc: "Sizin için en sevdiğim ürünleri listeledim",
  },
  activeButton: "amazon",
  type: "workspace",
  buttons: [
    {
      name: "Amazon",
      tag: "amazon",
      desc: "Uzun zamandır Amazon Prime üyesiyim ve alışveriş yapmayı seviyorum. İşte Amazon'dan tavsiye ettiğim ürünler",
      icon: <FaAmazonPay size={"20px"} />,
    },
    {
      name: "Etsy",
      tag: "etsy",
      desc: "Etsy el yapımı ve benzersiz ürünler için harika bir platform. İşte Etsy'de bulduğum bazı ürünler",
      icon: <BiLogoEtsy size={"20px"} />,
    },
 /*   {
      name: "Temu",
      tag: "temu",
      desc: "Temu is a great marketplace with a variety of products. Here are my picks from Temu",
      icon: <FaShoppingBasket size={"20px"} />,
    }, */
  ],
  items: [
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Fellow Clyde Electric Kettle",
      price: "$99",
      category: "Living",
      link: "https://www.example.com/",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "solo stove Ranger 2.0",
      price: "$199.99",
      category: "Living",
      link: "https://www.example.com/",
    },
    {
      tags: ["etsy"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Bang & Olufsen Beosound A1 2nd Gen",
      price: "$299",
      category: "Tech",
      link: "https://www.example.com/",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Flipper Zero",
      price: "$169",
      category: "Tech",
      link: "https://www.example.com/",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Sonos Ace",
      price: "$499",
      category: "Tech",
      link: "https://www.example.com/",
    },
    {
      tags: ["etsy"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "teenage engineering Computer-1",
      price: "$99",
      category: "Tech",
      link: "https://www.example.com/",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Nothing Watch Pro",
      price: "$69",
      category: "Tech",
      link: "https://www.example.com/",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Lofree TOUCH PBT Wireless Mouse",
      price: "$69.99",
      category: "Workspace",
      link: "https://www.example.com/",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Isamu Noguchi Akari 3A",
      price: "$400",
      category: "Living",
      link: "https://www.example.com/",
    },
    {
      tags: ["etsy"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "LEFF amsterdam Tube Alarm Clock",
      price: "$99",
      category: "Living",
      link: "https://www.example.com/",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Tidbyt Tidbyt",
      price: "$179",
      category: "Tech",
      link: "https://www.example.com/",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Apple Vision Pro",
      price: "$3499",
      category: "Tech",
      link: "https://www.example.com/",
    },
    {
      tags: ["etsy"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Sonos Era 100",
      price: "$279",
      category: "Tech",
      link: "https://www.example.com/",
    },
    {
      tags: ["temu"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "teenage engineering Field Desk",
      price: "$1599",
      category: "Workspace",
      link: "https://www.example.com/",
    },
    {
      tags: ["amazon"],
      imageUrl: "/images/workspace/144x144.webp",
      name: "Isamu Noguchi Akari 21A",
      price: "$400",
      category: "Living",
      link: "https://www.example.com/",
    },
  ],
};

export default workspaceData;