const about = [
  {
    image: "/images/about/skills/icon.svg",
    text: "Yetenek",
  },
  {
    image: "/images/about/experience/icon.svg",
    text: "Deneyim",
  },
  {
    image: "/images/about/education/icon.svg",
    text: "Eğitim",
  },
  {
    image: "/images/about/certifications/icon.svg",
    text: "Sertifikalar",
  },
  {
    image: "/images/about/testimonials/icon.svg",
    text: "Referanslar",
  },
];

export default about;