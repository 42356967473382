// React and external libraries
import React from "react";

// Components 
import Helmet from "../components/Helmet/Helmet";

// Data
import projectsData from "../components/Projects/data/projectsData";
import helmet from "../components/Helmet/data/helmet";
import TabMenuTwo from "../components/TabMenu/TabMenuTwo";
import ServicesCardOne from "../components/Services/ServicesCardOne"; 

import { motion } from "framer-motion";
 
import Contact from "./Contact"; 


const Home = () => {

  // Animation container to stagger child animations
  const container = {
    visible: {
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  // Animation for individual team items
  const item = {
    hidden: { y: 20, opacity: 0 }, // Initial hidden state
    visible: {
      y: 0,
      opacity: 1, // Animate to visible state
    },
  };


  return (
    <>
      {/* Helmet component to set the page title and meta description */}
      <Helmet title={helmet.home.title} desc={helmet.home.desc} />

      <ServicesCardOne />

      {/* TabMenu component for displaying projects in a tabbed layout */}
      <TabMenuTwo json={projectsData} />
 
      <Contact />

    </>
  );
};

export default Home;