const stories = [ 
  {
    url: "/images/profile/stories/Fxl.jpg",
    header: {
      heading: "Yaşa Fenerbahçe..",
      subheading: "Şükrü Saraçoğlu",
      profileImage: "/images/profile/stories/240x240.jpg",
    },
  },
  /*{
    url: "/images/profile/stories/1080x1920.webp",
    header: {
      heading: "Art Gallery",
      subheading: "Modern city life",
      profileImage: "/images/profile/stories/240x240.jpg",
    },
  } */
];

export default stories;