import {
  IoMdHome,
  IoMdCall,
  IoMdInformationCircle,
  IoMdPaper,
  IoMdHammer,
  IoMdBriefcase,
  IoMdConstruct,
  IoMdCart,
  IoMdPeople,
  IoMdListBox,
} from "react-icons/io";

import { BiSolidError } from "react-icons/bi";
import { RiPagesLine } from "react-icons/ri";
import { LuComputer } from "react-icons/lu";
import { GrContactInfo } from "react-icons/gr";
import { SiReaddotcv } from "react-icons/si";

const menuData = [
  { label: "Ana Sayfa", icon: <IoMdHome />, link: "/" },
  { label: "Hakkımda", icon: <IoMdInformationCircle />, link: "/about" },
  { label: "CV", icon: <SiReaddotcv />, link: "/cv" },
  { label: "Projeler", icon: <SiReaddotcv />, link: "/projects" }, 
  //{ label: "Blog", icon: <SiReaddotcv />, link: "/blog" },  
  { label: "Tavsiyeler", icon: <IoMdConstruct />, link: "/workspace" }, 
  { label: "Bio", icon: <GrContactInfo />, link: "/bio" },
  { label: "Ekipmanlarım", icon: <LuComputer />, link: "/setup" },  
  { label: "İletişim", icon: <IoMdCall />, link: "/contact" }, 
];

export default menuData;
